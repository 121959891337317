<template>
  <div class="d-table w-100">
    <template v-if="showEditor">
      <vue-editor
        ref="editor"
        v-model="content"
        data-cy="support-ticket-comment-input-editor"
      />
      <v-row
        dense
        class="mt-2"
      >
        <v-col>
          <v-file-input
            v-model="attachments"
            :label="$t('support.fields.addFiles')"
            multiple
            data-cy="ticket-attachement"
          />
          <template v-if="attachments.length > 1">
            <div
              v-for="attachment in attachments"
              :key="attachment.name"
              class="d-flex align-center"
            >
              <v-icon
                class="delete-icon"
                @click="removeAttachment(attachment)"
              >
                {{ DELETE_ICON }}
              </v-icon>
              {{ attachment.name }}
            </div>
          </template>
        </v-col>
        <v-col cols="auto">
          <v-btn
            :loading="loading"
            rounded
            depressed
            color="primary"
            data-cy="support-ticket-comment-input-editor-save-button"
            @click.prevent="onSave"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="ml-2"
            color="primary"
            @click.prevent="resetForm"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-text-field
        :label="$t('addComment')"
        class="no-input-details"
        data-cy="support-ticket-comment-input"
        @click="onClick"
      />
    </template>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { DELETE_ICON } from '@/constants/app'

export default {
  components: {
    VueEditor
  },
  data () {
    return {
      content: '',
      attachments: [],
      showEditor: false,
      DELETE_ICON
    }
  },
  computed: {
    ...mapState('support', ['loading']),
    ...mapGetters('support', { commentsGetter: 'comments' }),
    clientId () {
      return this.$route.params.clientId
    },
    ticketId () {
      return this.$route.params.ticketId
    },
    comments () {
      return this.commentsGetter(this.clientId, this.ticketId)
    },
    newCommentId () {
      return this.comments.length ? this.comments[this.comments.length - 1].id + 1 : 1
    }
  },
  methods: {
    ...mapActions('support', ['postTicketComment']),
    ...mapMutations('support', ['setErrors']),
    onClick () {
      if (!this.showEditor) {
        this.showEditor = true
        this.$nextTick(() => {
          this.$refs.editor.quill.focus()
        })
      }
    },
    onSave: async function () {
      this.setErrors({})
      await this.postTicketComment({
        clientId: this.clientId,
        ticketId: this.ticketId,
        data: {
          text: this.content,
          attachments: this.attachments
        },
        newCommentId: this.newCommentId
      })

      if (!this.showApiErrors) {
        this.resetForm()
      }
    },
    removeAttachment (attachment) {
      this.attachments = this.attachments.filter(at => at !== attachment)
    },
    resetForm () {
      this.content = ''
      this.showEditor = false
      this.attachments = []
    }
  }
}
</script>
